export default [
  {
    header: 'Settings',
    action: 'ROLE_CORE_CONSULTAR',
    resource: 'ACL'
  },
  {
    title: 'Register',
    icon: 'UserCheckIcon',
    action: 'ROLE_ADMIM_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Administrative',
        route: 'administrativeSettings',
        action: 'ROLE_ADMIM_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Atendimento',
        route: 'attendanceSettings',
        action: 'ROLE_ATEND_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Financial',
        route: 'financialSettings',
        action: 'ROLE_FINAN_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Protocolo',
        route: 'protocolSettings',
        action: 'ROLE_CORE_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Orders de Serviços',
        route: 'ordemServicoSettings',
        action: 'ROLE_CORE_CONSULTAR',
        resource: 'ACL'
      }
    ]
  },
  {
    title: 'Configurações',
    icon: 'ToolIcon',
    action: 'ROLE_CORE_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Cliente',
        route: 'settings-client',
        action: 'ROLE_CORE_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Diretoria',
        route: 'settings-diretoria',
        action: 'ROLE_CORE_CONSULTAR',
        resource: 'ACL'
      }
    ]
  },
  {
    title: 'Access',
    icon: 'UserIcon',
    action: 'ROLE_CORE_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Users',
        route: 'usersSettings',
        action: 'ROLE_USER_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Profile',
        route: 'profileSettings',
        action: 'ROLE_PERFIL_CONSULTAR',
        resource: 'ACL'
      }
    ]
  }
]
